.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0033a0; 
  color: white;
  padding: 10px;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.menu-button img {
  width: 24px;
  height: 24px;
}

.title {
  margin: 0;
  font-size: 20px;
}
