.bracket-container {
    width: 100%;
    overflow-x: auto !important; /* !important로 우선순위 높이기 */
    overflow-y: hidden !important;
    padding: 20px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    display: flex !important;
    align-items: flex-start !important;
    position: relative;
}

.bracket-inner {
    display: flex !important;
    flex-direction: row !important;
}

.bracket-title {
    text-align: center;
    color: blue;
    margin-bottom: 20px;
}

.seed {
    font-size: 11px;
}

.seed-item.custom-style {
    width: 200px;
    background-color: #2c2c2c;
    border-radius: 5px;
    color: #fff;
}

.seed-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2c2c2c;
    padding: 6px;
    border-radius: 5px;
    color: #fff;
    width: 120px;
}

.seed-date {
    margin-bottom: 6px;
    font-size: 0.6em;
}

.seed-team-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2px 0;
    margin-bottom: 2px;
}

.seed-team {
    flex: 1;
    text-align: left;
    font-size: 0.9em;
}

.seed-score {
    flex: 0;
    text-align: right;
    padding-left: 10px;
    min-width: 20px;
}

.seed-score-bold {
    font-weight: 0.8;
}

.no-team {
    color: red;
}
