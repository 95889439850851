.match-management-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh; /* 변경됨: 화면 전체 높이를 차지하게 설정 */
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto; /* 변경됨: 세로 스크롤 가능하게 설정 */
}

.match-list, .match-form {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.match-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* 각 줄에 요소들이 균등하게 배치되도록 설정 */
}

.match-list {
    max-height: none; /* 변경됨: 높이 제한 없이 전체적으로 보이도록 설정 */
    overflow: visible; /* 변경됨: 리스트 전체가 보이도록 설정 */
}

.match-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.match-item span {
    flex: 1;
    text-align: center;
}

.match-item button {
    margin-left: 10px;
}

button {
    padding: 10px 20px;
    height: 40px;
    background-color: #0e4d92;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #083a6b;
}

.save-button {
    margin-top: 10px;
}

.match-edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
}

.margin-10 {
    margin-bottom: 10px;
}