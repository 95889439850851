.input-group {
    flex: 0 0 48%; /* 한 줄에 두 개씩 배치되도록 너비 조정 (2%는 간격으로 사용) */
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
}

.input-group input,
.input-group select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}
