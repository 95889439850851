.match-detail-page {
  font-family: Arial, sans-serif;
  height: 100vh; /* 전체 화면 높이 설정 */
  display: flex;
  flex-direction: column;
}

.back-button {
  background: #d9d9d9;
    width: 35px;
    height: 35px;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

.match-detail-content {
  padding: 20px;
  overflow-y: auto; /* 세로 스크롤 활성화 */
  flex: 1; /* 화면 전체를 차지하도록 설정 */
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  margin: 0 5px;
}

.tab-button.active {
  background-color: #0e4d92;
  color: white;
}

.comments-section {
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.comments {
  margin-bottom: 10px;
}

.comment {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-user-icon {
  margin-right: 10px;
}

.comment-input-section {
  display: flex;
  align-items: center;
}

.comment-input-section input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.comment-submit-button {
  background-color: #0e4d92;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.tab-content {
  max-height: calc(100vh - 250px); /* 페이지 높이에 맞게 조정 */
  overflow-y: auto; /* 세로 스크롤 활성화 */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
  text-align: center;
}
