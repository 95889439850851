.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    background: rgba(255, 255, 255, 0.9); /* 반투명 배경 */
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
    transform: translateY(-200%);
    transition: transform 0.3s;
}

.modal-overlay.show .modal-content {
    transform: translateY(0);
}

.modal-header {
    display: flex;
    justify-content: flex-end;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.logo-container {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    width: 100px;
    height: auto;
}

.logo-text {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
    margin-top: 30px;
}

.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
}

.input-container input {
    width: 90%;
    padding: 10px 10px 10px 40px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #C5C9C7;
}

.remember-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.remember-container label {
    margin-left: 5px;
}

.forgot-password {
    text-decoration: none;
    color: #0033a0;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #0033a0;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
}

.login-button:hover {
    background-color: #002288;
}

.signup-prompt {
    font-size: 14px;
    color: #555555;
}

.signup-prompt a {
    color: #0033a0;
    text-decoration: none;
}

.signup-prompt a:hover {
    text-decoration: underline;
}
