.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 10px 0;
  border-top: 1px solid #ccc; 
  position: fixed;
  bottom: 0;
  width: calc(100vh * 0.5625); /* 세로 크기의 0.5625배로 가로 크기 설정 */
  margin: 0 auto;
}

.footer-item {
  flex: 1;
  text-align: center;
}

.footer-item img {
  width: 24px;
  height: 24px;
  transition: filter 0.3s ease;
}

.footer-item img:hover {
  filter: invert(28%) sepia(75%) saturate(4219%) hue-rotate(183deg) brightness(94%) contrast(95%);
}
