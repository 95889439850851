/* MyPage.css */

/* 전체 컨테이너 */
.mypage-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* 제목 */
.mypage-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

/* 라벨 */
.mypage-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

/* 입력 필드 */
.mypage-container input[type="text"],
.mypage-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

/* 버튼 */
.mypage-container button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.mypage-container button:hover {
    background-color: #0056b3;
}

/* 에러 메시지 */
.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}

/* 섹션 구분 */
.mypage-container > div {
    margin-bottom: 20px;
}
