.live-page {
  font-family: Arial, sans-serif;
}

.live-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.live-video {
  position: relative;
  width: 100%;
  max-width: 720px;
}

.live-video video {
  width: 100%;
  border-radius: 10px;
}

.live-status {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.comments-section {
  padding: 20px;
  background-color: #f0f0f0;
}

.comments-section h3 {
  margin-bottom: 10px;
}

.comments {
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.comment {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.comment-user-icon {
  margin-right: 10px;
}

.comment-text {
  word-break: break-all;
}

.comment-input-section {
  display: flex;
  align-items: center;
}

.comment-input-section input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.comment-submit-button {
  background-color: #0e4d92;
  color: white;
  border: none;
  padding: 10px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.comment-submit-button:hover {
  background-color: #083a6b;
}
