.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.signup-form label {
  margin: 10px 0 5px;
  font-weight: bold;
}

.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #F0F0F0;
  width: 100%;
}

.password-container {
  position: relative;
}

.password-container input {
  width: calc(100% - 40px);
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.verify-button {
  padding: 10px;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  
}

.check-button {
  padding: 10px;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  
}

.terms {
  margin: 20px 0;
}

.term-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.term-item input {
  margin-right: 10px;
}

.next-button {
  padding: 15px;
  background-color: #333333;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

.next-button:hover {
  background-color: #555555;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}
